import React from "react";
import PageWrapper from "../components/PageWrapper";
import HeroMini from "../sections/pear-landing/Hero-Mini-AM";
import RetailMediaNetworkAM from "../sections/pear-landing/Retail-Media-Network-Sales-AM.js";
import StartCapturingSalesAM from "../sections/pear-landing/Start-Capturing-Sales-AM";

import Newsletter from "../sections/landing2/Newsletter";

import { Helmet } from "react-helmet";

const PearIndex = () => {
  return (
    <>
      <Helmet>
        <title>
          Ecommerce Enhancements | Increase Retail Media Network Sales
          Spend
        </title>
        <meta
          name="description"
          content="Pear Commerce has unpacks how retail media networks can help drive more sales with ads that are available."
        />
      </Helmet>
      <PageWrapper
        headerConfig={{
          theme: "dark",
          align: "right",
          variant: "blue",
          button: "cta" // cta, account, null
        }}
        footerConfig={{
          style: "style3" //style1, style2
        }}
      >
        <HeroMini />
        <RetailMediaNetworkAM />

        <StartCapturingSalesAM />

        <Newsletter className="bg-default-4" />
      </PageWrapper>
    </>
  );
};
export default PearIndex;
